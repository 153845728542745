import React from 'react';
import { Radio, VStack, MenuGroup, Button, RadioGroup, MenuDivider } from '@companydotcom/potion';
import { useForm, Controller } from 'react-hook-form';
// import { setUserTileStateLite } from '../../../services/user/old-api/user-svc';
import { useTile } from '../../../providers';
import {
  useGetGlobalUserQuery,
  useSetUserTileStateLiteMutation,
} from '../../../services/user/user-api';

export const TileStateSwitcher = ({ refreshUserTileData }: any) => {
  const { data: globalUser } = useGetGlobalUserQuery();
  const [setUserTileStateLite] = useSetUserTileStateLiteMutation();
  const tile = useTile();
  const {
    handleSubmit,
    control,
    formState: { isSubmitting },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      tileState: tile.stateCurrent ?? '',
    },
  });
  const switchState = (stateCurrent: string) => {
    const call = async () => {
      try {
        if (globalUser) {
          const input = {
            userId: globalUser?.userId,
            tileId: tile.tileId,
            stateCurrent,
          };
          const res = await setUserTileStateLite({ input });
          await refreshUserTileData();
          return res;
        }
      } catch (err) {
        console.log('Error switching tile states: ', err);
        return err;
      }
    };

    return call();
  };

  const onSubmit = async (values: { tileState?: string }) => {
    try {
      if (values.tileState === tile.stateCurrent) {
        console.log('cant switch to state the tile is already in');
      } else if (values.tileState) {
        await switchState(values.tileState);
      }
    } catch (err: any) {
      console.log('Error switching tile state: ', err);
    }
  };

  return (
    <>
      <MenuDivider />
      <MenuGroup title="Set Tile State">
        <VStack px={4} alignItems="flex-start">
          <Controller
            name="tileState"
            control={control}
            render={({ field }) => (
              <RadioGroup {...field}>
                <VStack alignItems="flex-start">
                  {tile?.tileStates?.map(state => (
                    <Radio key={state?.stateName} value={state?.stateName}>
                      {state?.stateName}
                    </Radio>
                  ))}
                </VStack>
              </RadioGroup>
            )}
          />

          <Button
            isLoading={isSubmitting}
            alignSelf="center"
            colorScheme="purple"
            size="xs"
            onClick={handleSubmit(onSubmit)}
          >
            Set Tile State
          </Button>
        </VStack>
      </MenuGroup>
    </>
  );
};
